<template>
  <fragment>
    <div class="alert alert-warning" role="alert" v-if="isDataLoading">
      Processing response - One moment please.
      <div class="ld ld-ring ld-spin"></div>
    </div>
    <div v-else class="mb-3 mb-md-5">
      <div v-if="isSuccessful">
        <p>
          Thank you for responding to the invitation to bid on providing
          <strong>{{response.tradeType}}</strong> for Project
          <strong>{{response.ocaaNumber}} {{response.title}}</strong>. Your response has been received.
        </p>
        <router-link class="btn btn-success invitation-response-btn" to="/login" role="button">
          <span>Login</span>
        </router-link>
      </div>
      <div class="alert alert-danger" v-else>
        Unable to process your response - please contact an administrator.
      </div>
    </div>
  </fragment>
</template>

<style lang="scss" scoped>
.invitation-response-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: 145px;
  height: 45px;
  margin: 36px auto;
  display: block;
  font-size: 14px;
  cursor: pointer;

  span {
    display: inline-block;
    vertical-align: middle;
    margin-top: 5px;
  }
}
</style>

<script>
import { BidInvitationService } from "@/services/";

export default {
  name: "respond-to-bid",
  data: function() {
    return {
      response: {},
      isDataLoading: true,
      isSuccessful: false
    };
  },
  mounted() {
    let id = this.$route.query.id || null;
    let status = this.$route.query.status || null;

    if (!id || !status) {
      this.isDataLoading = false;
      return;
    }

    BidInvitationService.respondToInvitation(id, status)
      .then(result => {
        this.response = result.data;
        this.isDataLoading = false;
        this.isSuccessful = true;
      })
      .catch(() => {
        this.isDataLoading = false;
      });
  }
};
</script>
